import { Col, Row } from "react-bootstrap";
import { IShallowSolarPlantView } from "../../interfaces/shallow-solar-plant-view";
import RadioButton from "../../ui-elements/radio-button/radio-button";
import styles from "./inverter-plant-card.module.scss";

interface IProps {
  solarPlant: IShallowSolarPlantView;
  onChange: (selectedPlantId: string) => void;
  checked: boolean;
}

const InverterPlantCard = (props: IProps) => {
  const { solarPlant, onChange, checked } = props;

  const handleSelected = () => {
    if (!solarPlant.isConnected) {
      onChange && onChange(solarPlant.plantId);
    }
  };

  return (
    <div
      className={`${
        solarPlant.isConnected
          ? styles.disabledCardContainer
          : styles.cardContainer
      } px-3 py-2 ${!solarPlant.isConnected && `cursor-pointer`}`}
      onClick={handleSelected}
    >
      <Row className="align-items-center">
        <Col>
          <Row>
            <Col className="text-light font-weight-500 font-size-12">
              {solarPlant.plantId}
            </Col>
          </Row>
          <Row>
            <Col className="text-dark font-weight-500 font-size-16">
              {solarPlant.plantName}
            </Col>
          </Row>
          <Row>
            <Col className="text-light font-weight-400 font-size-10">
              {solarPlant.address}
            </Col>
          </Row>
        </Col>
        {!solarPlant.isConnected && (
          <Col className="col-auto">
            <RadioButton
              checked={checked}
              onChange={() => onChange && onChange(solarPlant.plantId)}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InverterPlantCard;
