import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetPaginatedCustomerSolarPlantListQuery } from "../../../../redux/api/consumer/consumerAPI";
import IListAllSolarPlantsResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/list-all-solar-plants-response-dto";
import { IShallowSolarPlantView } from "../../../../shared/oversight-core/interfaces/shallow-solar-plant-view";
import InverterPlantCard from "../../../../shared/oversight-core/shared-components/inverter-plant-card/inverter-plant-card";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import Pagination from "../../../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";

interface IProps extends ModelContainerProps {
  show: boolean;
  setSelectedPlantId: React.Dispatch<React.SetStateAction<string>>;
  selectedPlantId: string;
}

interface ISearchFilter {
  searchValue: string;
  pageNumber: number;
  pageSize?: number;
}

const initialFilters: ISearchFilter = {
  searchValue: "",
  pageNumber: 0,
  pageSize: 5,
};

const InverterLinkModal = (props: IProps) => {
  const { show, setSelectedPlantId, selectedPlantId, ...rest } = props;

  const [filters, setFilters] = useState<ISearchFilter>({
    ...initialFilters,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [solarPlantList, setSolarPlantList] = useState<
    IShallowSolarPlantView[]
  >([]);
  const [searchText, setSearchText] = useState("");

  const [triggerSolarPlantList, { isFetching }] =
    useLazyGetPaginatedCustomerSolarPlantListQuery();

  useEffect(() => {
    if (show) {
      triggerSolarPlantList({
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
        searchValue: filters.searchValue,
      })
        .unwrap()
        .then((res: IListAllSolarPlantsResponseDTO) => {
          setTotalElements(res.genericPage.totalElements);
          setSolarPlantList(res.genericPage.elements);
        })
        .catch(() => {
          setTotalElements(0);
          setSolarPlantList([]);
        });
    }
  }, [triggerSolarPlantList, filters, show]);

  useEffect(() => {
    if (show) {
      setSearchText("");
      setFilters({ ...initialFilters });
      setSelectedPlantId("");
    }
  }, [show]);

  const onSearch = () => {
    if (searchText) {
      setFilters({
        searchValue: searchText,
        pageNumber: 0,
      });
    }
  };

  useEffect(() => {
    if (!searchText) {
      setFilters({ ...initialFilters });
    }
  }, [searchText]);

  return (
    <ModalContainer
      show={show}
      {...rest}
      title="Connect Plant"
      confirmButtonText="Connect"
      cancelButtonText="Cancel"
      size="modal-lg"
      disabled={!selectedPlantId}
    >
      <>
        <Row className="me-0">
          <Col>
            <Search
              onSearch={onSearch}
              handleSearchChange={(searchText) => {
                setSearchText(searchText);
              }}
              placeholder="Search with Name or Plant Name"
              value={searchText}
            />
          </Col>
        </Row>
        {isFetching ? (
          <SpinnerModal show={isFetching} withOverlay={false} />
        ) : (
          <>
            <div className="mt-4">
              {solarPlantList.map((sp, index) => {
                return (
                  <Row key={index} className="mt-2">
                    <Col>
                      <InverterPlantCard
                        solarPlant={sp}
                        onChange={(selectedPlantId) => {
                          setSelectedPlantId(selectedPlantId);
                        }}
                        checked={sp.plantId === selectedPlantId}
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
            {solarPlantList.length > 0 && (
              <Pagination
                itemsPerPage={5}
                length={totalElements}
                currentPage={filters.pageNumber + 1}
                updateCurrentPage={(pn) => {
                  setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
                }}
              />
            )}
          </>
        )}
      </>
    </ModalContainer>
  );
};

export default InverterLinkModal;
