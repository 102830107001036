import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  spaceClusterName: string;
  plantName: string;
}

const InverterUnlinkModal = (props: IProps) => {
  const { show, spaceClusterName, plantName, ...rest } = props;

  return (
    <ModalContainer
      show={show}
      {...rest}
      title="Disconnect Plant?"
      confirmButtonText="Disconnect"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
      size="modal-lg"
    >
      <>
        <div className="font-size-14 font-weight-400 text-light">
          Are you sure
          <span className="font-weight-700 text-light">
            &nbsp;{plantName}&nbsp;
          </span>
          should be disconnected from
          <span className="font-weight-700 text-light">
            &nbsp;{spaceClusterName}.&nbsp;
          </span>
          This will remove your ability to access solar inverter data..
        </div>
      </>
    </ModalContainer>
  );
};

export default InverterUnlinkModal;
