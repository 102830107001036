export enum EPlantType {
  ALL = "ALL",
  BATTERY_STORAGE = "BATTERY_STORAGE",
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL_ROOFTOP = "COMMERCIAL_ROOFTOP",
  GROUND_MOUNTED = "GROUND_MOUNTED",
}

export const plantTypeText = {
  [EPlantType.ALL]: "All",
  [EPlantType.BATTERY_STORAGE]: "Battery Storage",
  [EPlantType.RESIDENTIAL]: "Residential",
  [EPlantType.COMMERCIAL_ROOFTOP]: "Commercial Rooftop",
  [EPlantType.GROUND_MOUNTED]: "Ground Mounted",
};
