import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { selectAuth } from "../redux/features/auth/auth-slice";
import DashboardLayout from "../shared/components/dashboard-layout/dashboard-layout";
import PublicRoute from "../shared/components/public-route/public-route";
import { EPrivileges } from "../shared/oversight-core/enums/privileges";
import { UserRole } from "../shared/oversight-core/enums/user-roles";
import { AppRoute } from "../shared/oversight-core/interfaces/app-routes";
import IPageInfo from "../shared/oversight-core/interfaces/page-info";
import ComingSoon from "../shared/oversight-core/shared-components/coming-soon/coming-soon";
import NotFound from "../shared/oversight-core/shared-components/not-found/not-found";
import PrivateRoute from "../shared/oversight-core/shared-components/private-route/private-route";
import Unauthorized from "../shared/oversight-core/shared-components/unauthorized/unauthorized";
import ForgotPassword from "../shared/oversight-core/shared-pages/forgot-password/forgot-password";
import MobileNumberOtpVerification from "../shared/oversight-core/shared-pages/mobile-number-otp-verification/mobile-number-otp-verification";
import ResetPassword from "../shared/oversight-core/shared-pages/reset-password/reset-password";
import UserProfile from "../shared/oversight-core/shared-pages/user-profile/user-profile";
import RemoveTrailingSlash from "../shared/oversight-core/utils/removeTrailingSlash";
import Controllers from "./controllers/controllers";
import AdminDashboard from "./dashboard/dashboard";
import AdminLogin from "./login/login";
import SolarPlantUserList from "./solar-plant-user-list/solar-plant-user-list";
import Solar from "./solar/solar";
import AddUpdateManualDemandControlRequest from "./usage-guide/components/add-update-manual-demand-control-request/add-update-manual-demand-control-request";
import AutomatedDemandControlRequest from "./usage-guide/components/automated-demand-control-request/automated-demand-control-request";
import Requests from "./usage-guide/components/requests/requests";
import DemandResponseProgram from "./usage-guide/demand-response-program/demand-response-program";
import UsageGuide from "./usage-guide/usage-guide";
import AdminUserView from "./user/user-dashboard";
import Users from "./users/users";

export const publicRoutes: IPageInfo[] = [
  {
    name: "Forgot Password",
    path: AppRoute.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    enableRoute: true,
  },
  {
    name: "Reset Password",
    path: AppRoute.RESET_PASSWORD,
    component: <ResetPassword />,
    enableRoute: true,
  },
  {
    name: "Mobile Number Verification",
    path: AppRoute.MOBILE_NUMBER_VERIFICATION,
    component: <MobileNumberOtpVerification />,
    enableRoute: true,
  },
  {
    name: "Admin Login",
    path: AppRoute.ADMIN_LOGIN,
    component: <AdminLogin />,
    enableRoute: true,
  },
];

export const adminPrivateRoutes: IPageInfo[] = [
  {
    name: "Admin",
    path: AppRoute.ADMIN,
    component: <DashboardLayout />,
    root: true,
    subRoutes: [
      {
        name: "Dashboard",
        icon: "home",
        path: AppRoute.ADMIN_DASHBOARD,
        index: true,
        component: <AdminDashboard />,
        enableRoute: true,
        bgColor: "activeBg-7",
        bgColorIcon: "#383941",
        showOnDrawer: true,
        privilege: EPrivileges.DASHBOARD_READ_PRIVILEGE,
      },
      {
        name: "Users",
        path: AppRoute.ADMIN_USERS,
        icon: "person_outline",
        component: <Users />,
        enableRoute: true,
        bgColor: "activeBg-2",
        bgColorIcon: "#383941",
        showOnDrawer: true,
        privilege: EPrivileges.USER_MANAGEMENT_READ_PRIVILEGE,
      },
      {
        name: "User",
        path: AppRoute.ADMIN_USERS + "/:id",
        icon: "person_outline",
        component: <AdminUserView />,
        enableRoute: true,
        bgColor: "activeBg-2",
        bgColorIcon: "#383941",
        showOnDrawer: false,
        privilege: EPrivileges.USER_MANAGEMENT_READ_PRIVILEGE,
      },
      {
        name: "Usage Guide",
        path: AppRoute.ADMIN_USAGE_GUIDE,
        icon: "history_toggle_off",
        component: <UsageGuide />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: true,
        privilege: EPrivileges.USAGE_GUIDE_READ_PRIVILEGE,
      },
      {
        name: "New Automated Demand Response",
        path: AppRoute.AUTOMATED_DEMAND_CONTROL,
        icon: "history_toggle_off",
        component: <AutomatedDemandControlRequest />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_WRITE_PRIVILEGE,
      },
      {
        name: "New Automated Demand Response",
        path: AppRoute.AUTOMATED_DEMAND_CONTROL + "/:id",
        icon: "history_toggle_off",
        component: <AutomatedDemandControlRequest />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_WRITE_PRIVILEGE,
      },
      {
        name: "New Manual Demand Response",
        path: AppRoute.ADD_MANUAL_DEMAND_CONTROL,
        icon: "history_toggle_off",
        component: <AddUpdateManualDemandControlRequest />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888F",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_WRITE_PRIVILEGE,
      },
      {
        name: "Controllers",
        path: AppRoute.ADMIN_CONTROLLERS,
        icon: "smart_outlet",
        component: <Controllers />,
        enableRoute: true,
        bgColor: "activeBg-4",
        bgColorIcon: "#30858A",
        showOnDrawer: true,
        privilege: EPrivileges.CONTROLLER_READ_PRIVILEGE,
      },
      {
        name: "Requests",
        path: AppRoute.REQUESTS,
        icon: "history_toggle_off",
        component: <Requests />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_READ_PRIVILEGE,
      },
      {
        name: "Demand Response Program (DRP)",
        path: AppRoute.ADMIN_DEMAND_RESPONSE_PROGRAM + "/:id",
        icon: "history_toggle_off",
        component: <DemandResponseProgram />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
        privilege: EPrivileges.USAGE_GUIDE_READ_PRIVILEGE,
      },
      {
        name: "Settings",
        path: AppRoute.ADMIN_SETTINGS,
        icon: "settings",
        component: <ComingSoon />,
        enableRoute: true,
        bgColor: "activeBg-6",
        bgColorIcon: "##383941",
        showOnDrawer: true,
      },
      {
        name: "Solar Plants",
        path: AppRoute.ADMIN_SOLAR_PLANTS,
        icon: "person_outline",
        component: <SolarPlantUserList />,
        enableRoute: true,
        bgColor: "activeBg-2",
        bgColorIcon: "#383941",
        showOnDrawer: false,
        privilege: EPrivileges.ONLY_EXTERNAL_GENERATION_READ_PRIVILEGE,
      },
      {
        name: "",
        path: AppRoute.ADMIN_SOLAR + "/:id",
        icon: "sunny",
        component: <Solar />,
        enableRoute: true,
        bgColor: "activeBg-13",
        bgColorIcon: "#AD792B",
        showOnDrawer: false,
        privilege: EPrivileges.ONLY_EXTERNAL_GENERATION_READ_PRIVILEGE,
      },
      {
        name: "Profile",
        path: AppRoute.ADMIN_PROFILE,
        component: <UserProfile />,
        enableRoute: true,
        showOnDrawer: false,
        privilege: EPrivileges.GENERAL_READ_PRIVILEGE,
      },
      {
        name: "404 Page Not Found",
        path: AppRoute.ADMIN_NOT_FOUND,
        component: <NotFound />,
        showOnDrawer: false,
      },
    ],
  },
];

const AppRoutes = () => {
  const auth = useSelector(selectAuth);
  const getRoutes = (routes: IPageInfo[]) => {
    return routes.map((privateRoutes: IPageInfo, index) => (
      <Route
        key={index}
        path={privateRoutes.path}
        element={privateRoutes.component}
      >
        {privateRoutes.subRoutes?.map((subRoute: IPageInfo, index) => {
          const route = [
            <Route
              key={index}
              path={subRoute.path}
              element={
                !subRoute.privilege ||
                auth.privileges.includes(subRoute.privilege) ? (
                  subRoute.component
                ) : (
                  <Unauthorized />
                )
              }
            />,
          ];

          if (subRoute.index) {
            route.push(
              <Route
                key={index + subRoute.path}
                index
                element={<Navigate to={subRoute.path} />}
              />
            );
          }
          return route;
        })}
      </Route>
    ));
  };

  return (
    <Fragment>
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<Navigate to={AppRoute.ADMIN_LOGIN} />} />
          {publicRoutes.map((p: IPageInfo, index) => {
            return <Route key={index} path={p.path} element={p.component} />;
          })}
          <Route path="*" element={<Navigate to={AppRoute.ADMIN_LOGIN} />} />
        </Route>

        <Route path="/" element={<PrivateRoute role={UserRole.ROLE_ADMIN} />}>
          {getRoutes(adminPrivateRoutes)}
        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
