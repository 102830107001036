import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyAdminListAllSmartControllersQuery } from "../../redux/api/consumer/consumerAPI";
import AdminListAllSmartControllersRequestDTO from "../../shared/oversight-core/dtos/request-dtos/admin-list-all-smart-controllers-request-dto";
import AdminListAllSmartControllersResponseDTO from "../../shared/oversight-core/dtos/response-dtos/admin-list-all-smart-controllers-response-dto";
import { EConnectionStatus } from "../../shared/oversight-core/enums/connection-status";
import { SearchField } from "../../shared/oversight-core/enums/search-field";
import { ISpaceClusterSmartPlugViews } from "../../shared/oversight-core/interfaces/space-cluster-smart-plug-views";
import ActiveInactiveIndicator from "../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect from "../../shared/oversight-core/ui-elements/app-select/app-select";
import DataTable from "../../shared/oversight-core/ui-elements/data-table/data-table";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import { showErrorMessage } from "../../shared/oversight-core/utils/toast";
import styles from "./controllers.module.scss";
import Pagination from "./pagination/pagination";
import Search from "./search/search";

const initialFilters: AdminListAllSmartControllersRequestDTO = {
  searchField: SearchField.NAME,
  searchValue: "",
  pageNumber: 0,
  pageSize: 10,
  ascending: true,
};

const Controllers = () => {
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] =
    useState<AdminListAllSmartControllersRequestDTO>({ ...initialFilters });
  const [smartDevicesList, setSmartDevicesList] = useState<
    ISpaceClusterSmartPlugViews[]
  >([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchOptions = [
    { value: SearchField.USER_FULLNAME, label: "By User Name" },
    { value: SearchField.NAME, label: "By Controller Name" },
    { value: SearchField.SERIAL, label: "By Serial Key" },
  ];

  const [triggerSmartDeviceList] = useLazyAdminListAllSmartControllersQuery();

  useEffect(() => {
    let _filter: AdminListAllSmartControllersRequestDTO;
    if (filters?.searchValue?.trim()) {
      _filter = filters;
    } else {
      _filter = { ...filters, searchField: SearchField.ALL };
    }
    setLoading(true);
    setSmartDevicesList([]);
    triggerSmartDeviceList(_filter)
      .unwrap()
      .then((response: AdminListAllSmartControllersResponseDTO) => {
        setTotalElements(response.smartDevices?.totalElements || 0);
        setSmartDevicesList(response.smartDevices.elements);
      })
      .catch(() => {
        showErrorMessage("Sorry, An error occurred");
      })
      .finally(() => setLoading(false));
  }, [filters, triggerSmartDeviceList]);

  const getPlaceholder = () => {
    switch (filters.searchField) {
      case SearchField.SERIAL:
        return "Search Controller Serial Key";
      case SearchField.NAME:
        return "Search Controller Name";
      case SearchField.USER_FULLNAME:
        return "Search User Name";
      default:
        return "Search User Account Number";
    }
  };

  return (
    <div
      className={`${styles.usersListOverflow} container-white position-relative py-4`}
    >
      <Row className="align-items-center">
        <Col className="col-12 col-xl-6">
          <Row className="align-items-center">
            <Col className="col-4 col-lg-4 col-xl-5 pe-0">
              <AppSelect
                selectedValue={searchOptions.filter(
                  (a) => a.value === filters.searchField
                )}
                options={searchOptions}
                onChangeOption={(selectedOption) => {
                  setFilters((ps) => ({
                    ...ps,
                    searchField: selectedOption.value as SearchField,
                    pageNumber: 0,
                  }));
                }}
                borderRadiusStyle={"Right"}
                className="font-size-12"
              />
            </Col>
            <Col className="col-8 col-lg-6 col-xl-6 ps-0">
              <Search
                placeholder={getPlaceholder()}
                onSearch={() => {
                  setFilters((ps) => ({
                    ...ps,
                    searchValue: searchInput,
                    pageNumber: 0,
                  }));
                }}
                onChange={(input) => setSearchInput(input)}
                value={searchInput}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-2 col-xl-auto mt-3 mt-xl-0 text-center">
          <p
            className="mb-0 text-primary font-size-12 font-weight-500 cursor-pointer"
            onClick={() => {
              setFilters({ ...initialFilters });
              setSearchInput("");
            }}
          >
            Reset Filters
          </p>
        </Col>
      </Row>
      {smartDevicesList.length > 0 ? (
        <>
          <DataTable
            headers={[
              { id: 1, columnName: "Serial" },
              { id: 2, columnName: "Linked\u00A0to" },
              { id: 3, columnName: "Activation" },
              { id: 4, columnName: "User" },
              { id: 5, columnName: "Power State" },
              { id: 6, columnName: "Active State" },
            ]}
            data={smartDevicesList.map((device) => {
              return {
                data: [
                  <>
                    <div className="d-flex align-items-center">
                      <div>
                        {device.smartDevice?.serialKey
                          ? `${device.smartDevice?.serialKey}`
                          : "-"}
                      </div>
                      <div className="ms-1">
                        <ActiveInactiveIndicator
                          isActive={
                            device.smartDevice.deviceConnectionState ===
                            EConnectionStatus.CONNECTED
                          }
                        />
                      </div>
                    </div>
                  </>,
                  <>
                    {device.smartDevice?.linkedPowerConsumer
                      ? `${device.smartDevice?.linkedPowerConsumer?.name}`
                      : "-"}
                  </>,
                  <>
                    {device.smartDevice?.activationDate
                      ? `${moment(new Date(device.smartDevice?.activationDate))
                          .utcOffset("+05:30")
                          .format("YYYY-MM-DD HH:mm")}`
                      : "-"}
                  </>,
                  <>
                    {device.user?.firstName && device.user?.lastName
                      ? `${device.user?.firstName} ${device.user?.lastName}`
                      : "-"}
                  </>,
                  <>
                    {device.smartDevice?.dynamicState?.powerState
                      ? `${device.smartDevice.dynamicState.powerState}`
                      : "-"}
                  </>,
                  <>
                    {device.smartDevice?.activeStatus
                      ? `${device.smartDevice.activeStatus}`
                      : "-"}
                  </>,
                ],
              };
            })}
          />
          <Pagination
            itemsPerPage={filters.pageSize}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
            }}
          />
        </>
      ) : (
        <>
          {!loading && (
            <div className="container-dash mt-4">
              <Col className="text-center text-light font-size-12">
                There are no users available for the selected filters.
              </Col>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={loading} />
    </div>
  );
};

export default Controllers;
