import { Col, Row } from "react-bootstrap";
import styles from "./indicator-tabs.module.scss";

interface ITabs {
  color: string;
  name: string;
}

interface IProps {
  tabs: ITabs[];
}

const IndicatorTabs = (props: IProps) => {
  return (
    <Row className="mx-0 gap-3">
      {props.tabs.map((i) => {
        return (
          <Col className={`col-auto ${styles.tabContainer} py-1 px-2`}>
            <Row className="align-items-center">
              <Col className={`col-auto pe-0`}>
                <div
                  className={`${styles.indicator} ${styles[`${i.color}`]}`}
                ></div>
              </Col>
              <Col className="text-light font-size-14 font-weight-400 ps-2">
                {i.name}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default IndicatorTabs;
